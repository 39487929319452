<template>
<section>
    <div class="page-header">
        <h3 class="page-title">
        {{title}} <span class="text-muted">{{searchResult.length}}</span>
        </h3>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <span v-if="!activePerpage">
                    <!-- n'affiche rien -->
                </span>
                <span v-else class="computer">
                    <b-form-select size="md" variant="gradient-primary" v-model="perPage" style="width:auto">
                        <b-form-select-option value="3">3</b-form-select-option>
                        <b-form-select-option value="5">5</b-form-select-option>
                        <b-form-select-option value="10">10</b-form-select-option>
                        <b-form-select-option value="25">25</b-form-select-option>
                        <b-form-select-option value="50">50</b-form-select-option>
                    </b-form-select>
                </span>

                <b-pagination class="separated pagination-danger pl-2 computer"
                :total-rows="rows"
                v-model="currentPage"
                :per-page="perPage"
                aria-controls="id">
                </b-pagination>
                <!-- <b-form-input class="mt-2" size="md" v-if="activeSearch" v-model="search"></b-form-input> -->
            </ol>
            <ol class="breadcrumb">
                <b-form-input class="mt-2" size="md" v-if="activeSearch" v-model="search" placeholder="Rechercher un produit"></b-form-input>
            </ol>
        </nav>
    </div>
    <div class="row product-item-wrapper" v-if="searchResult.length>0">
        <div class="col product-item stretch-card" :class="col" v-for="(item, i) in paginateItems" :key="i"
        id="id"
        :current-page="currentPage"
        :per-page="perPage">
            
            <div class="card">
                <div class="card-body">
                    <div class="action-holder">
                        <!-- <div class="sale-badge bg-gradient-success">New</div> -->
                        <span class="favorite-button"><i class="mdi mdi-heart-outline"></i></span>
                    </div>
                    <div class="product-img-outer">
                        <img class="product_image" src="@/assets/images/import/3.jpg" alt="prduct image" @click="showDetails(item)" style="cursor:pointer">
                    </div>
                    <p class="product-title" @click="showDetails(item)" style="cursor:pointer">{{item.name}}</p>
                    <p class="product-price">{{item.price}} €</p>
                    <p>Miel bio, préparé artisanalement. </p>
                    <star-rating class="mx-auto d-flex align-items-start"  v-bind:increment="0.5" v-bind:rating="2.5" v-bind:max-rating="5" v-bind:star-size="15" v-bind:show-rating="false" v-model="rating"></star-rating>
                    <p class="product-description d-flex justify-content-between border-top pt-2 text-muted">
                        <span> <i class="mdi mdi-weight"></i> {{item.weight}} kg</span>
                        <span> <i class="mdi mdi-glass-stange"></i> {{item.volume}} ml</span>
                        <span> <i class="mdi mdi-map-marker"></i> {{item.shippinglocation}}</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="col mobile">
            <b-pagination class="separated pagination-danger pl-2"
            :total-rows="rows"
            v-model="currentPage"
            :per-page="perPage"
            aria-controls="id">
            </b-pagination>
            <span v-if="!activePerpage">
                    <!-- n'affiche rien -->
            </span>
        </div>

        <div class="col-md-12 d-flex justify-content-center">
            <button class="btn" v-if=" paginateItems.length< searchResult.length" @click="showMore()">Voir plus <i class="mdi mdi-arrow-down-drop-circle"></i></button>
        </div>
    </div>
    <div v-else>
        <h4>
            {{loadingMessage}}
        </h4> 
    </div>
</section>
</template>

<script>
import StarRating from 'vue-star-rating'
export default {
    name: 'paginateCard',
    components: {
        StarRating
    },
    data() {
        return {
            currentPage: 1,
            rating: 3,
            perPage: 4
        }
    },
    props: {
        activeSearch: Boolean,
        activePerpage: Boolean,
        // perPage: {
        //     default: 6
        // },
        id: String,
        items: Array,
        title: String,
        col: String,
        name: String,
        price: Number,
        search: String,
        loadingMessage: String
    },
    computed:{
        rows () {
            return this.items.length
        },
        searchResult() {
            return this.search ? this.items.filter(
                item => item.name.toLowerCase().includes(this.search.toLowerCase())
            )
            :this.items
        },
        paginateItems () {
            return this.searchResult.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            )
        }
        // upperCase(name) {
        //     return name.charAt(0).toUpperCase()+ name.slice(1)
        // }
    },
    methods: {
        showDetails(item) {
            // console.log(item)
            const ulid= item.ulid
            this.$router.push({
                name:'product',
                params: { ulid }
            })
        },
        showMore() {
            this.perPage += 6
            // console.log('paginateItems in after showMore clicking',this.paginateItems)
        }
    },
    mounted() {
    }
}
</script>

<style scoped>
@media screen and (max-width: 992px) {
  .computer {
      display: none !important
  }
}
@media screen and (min-width: 992px) {
  .mobile {
      display: none !important
  }
}
</style>