<template>
    <section class="content-wrapper">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3">
                                <!-- Filtre produits "computer" -->
                                <div class="sidebarCategories">
                                    <div class="mt-4">
                                        <h6>FILTRER LES PRODUITS</h6>
                                        <div role="tablist" class="accordion accordion-bordered">
                                            <!-- Tag pous les filtres sélectionnés -->
                                             <!-- <b-form-tags input-id="tags-basic" v-model="tagValue" size='sm' placeholder=""></b-form-tags> -->

                                             <!-- filtre par catégories -->
                                            <b-card class="mx-0" no-body>
                                                <b-card-header class="py-2" header-tag="header" role="tab">
                                                <a href="javascript:void(0);" v-b-toggle.categories>
                                                    Par catégories
                                                </a>
                                                </b-card-header>
                                                <b-collapse visible id="categories" accordion="categories" role="tabpanel">
                                                    <b-card-body class="filter-category">
                                                        <div class="form-check form-check-primary">
                                                            <label class="form-check-label">
                                                            <input type="checkbox" class="form-check-input" value="allCategories" v-model="allCategories">
                                                            Toutes les catégories
                                                            <i class="input-helper"></i>
                                                            </label>
                                                        </div>
                                                        <div class="form-check form-check-primary" v-for="(category, i) in $store.state.categories" :key="i">
                                                            <label class="form-check-label">
                                                            <input type="checkbox" :value="category.ulid" :id="category.category" v-model="checkedCategories" class="form-check-input" >
                                                            {{category.category}}
                                                            <i class="input-helper"></i>
                                                            </label>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                            
                                             <!-- trier par -->
                                            <b-card class="mx-0" no-body>
                                                <b-card-header class="py-2" header-tag="header" role="tab">
                                                <a href="javascript:void(0);" v-b-toggle.tri>
                                                    Trier par
                                                </a>
                                                </b-card-header>
                                                <b-collapse id="tri" accordion="tri" role="tabpanel">
                                                    <b-form-group label="">
                                                        <b-form-radio-group  class="d-flex flex-column" id="radios2" name="radioSubComponent" :options="sort">
                                                        </b-form-radio-group>
                                                    </b-form-group> 
                                                </b-collapse>
                                            </b-card>
                                            
                                            <!-- filtre par prix -->
                                            <b-card class="mx-0" no-body>
                                                <b-card-header class="py-2" header-tag="header" role="tab">
                                                <a href="javascript:void(0);" v-b-toggle.price>
                                                    Par Date
                                                </a>
                                                </b-card-header>
                                                <b-collapse id="price" accordion="price" role="tabpanel">
                                                    <b-form-group label="">
                                                        <b-form-radio-group class="d-flex flex-column" id="radios2" name="radioSubComponent">
                                                        <b-form-radio value="all" v-model="selectedSortDate">Tout</b-form-radio>
                                                        <b-form-radio value="days" v-model="selectedSortDate">30 derniers jours</b-form-radio>
                                                        <b-form-radio value="month" v-model="selectedSortDate">3 dernier mois</b-form-radio>
                                                        </b-form-radio-group>
                                                    </b-form-group>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                    </div>
                                </div>
                                <!-- media screen < 922px -->
                                <div class="sidebarCategories2">
                                    <div role="group" class="input-group">
                                        <input type="text" placeholder="Recherche" class="form-control" id="__BVID__226">
                                        <div class="input-group-append">
                                            <div class="input-group-text bg-primary text-white">
                                            <span>
                                                <i class="fa fa-search"></i>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="navbar-nav-scroll mt-4">
                                        <ul class="navbar-nav bd-navbar-nav flex-row">
                                            <li class="nav-item" v-for="(category, y) in $store.state.categories" :key="y">
                                                <a class="nav-link ml-2"><label data-v-5f1c8a7a="" class="badge badge-outline-dark">{{category.category}}</label></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-lg-9" >
                                <simple-card activeSearch  id="popularProduct" :search="shopSearch" :items="filteredProduct" title="Produits" :loadingMessage="loadingMessage" col="col-lg-6 col-md-6 col-sm-6 col-12"
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import simpleCard from '../components/pagination/simpleCard.vue'
const axios = require('axios').default
export default {
  components: { 
      simpleCard
       },
    name:'shop',
    data () {
        return {
            products:'', //tous les produits
            checkedCategories: [
            ], //v-model an'ilay checked box
            filteredProduct:[], //résultat des produits filtrés,
            selectedSortDate:'',
            selectedSort:'newProducts',
            sort: [
                { value: 'newProducts', text: 'Nouveaux produits'},
                { value: 'mostSold', text: 'Les plus vendus'},
                { value: 'promotion', text: 'Promotion'}
            ],
            shopSearch:'',
            loadingMessage: '',
            tagValue: ['Toute catégorie', 'Produits récents']
        }
    },
    computed: {
        allCategories: {
            get: function () {
                // effet inverse des checkbox lors du cochage
                return this.$store.state.categories ? this.checkedCategories.length == this.$store.state.categories.length : false;
            },
            set: function (value) {
                var selected = [];

                if (value) {
                    this.$store.state.categories.forEach(function (category) {
                        selected.push(category.ulid);
                        console.log({selected});
                    });
                }
                this.checkedCategories = selected
            }
        },
        newProducts() {
            if(this.selectedSort=="mostSold"){
               return this.filteredProduct.slice().sort(( a, b) => new Date (b.datepublished) - new Date (a.datepublished))
                }
           else {
               return this.filteredProduct
           }
        }
    },
    watch: {
        checkedCategories(value) {
            console.log('this.products', this.products)
            console.log('checkedValue', value);
            let filteredProduct= this.products.filter(product => {
                // let itemFound=false
                for( let item of value ) {
                    if(product.ulidcategory.includes(item))
                    return true;
                }
                return false
            })
            this.filteredProduct= filteredProduct
            console.log('this.filteredProduct', this.filteredProduct)
        },
        selectedSort (value) {
            // console.log({value});
            value==="newProducts"?this.filteredProduct= this.filteredProduct.sort(( a, b) => new Date (b.datepublished) - new Date (a.datepublished)):this.filteredProduct
        }
    },
    methods: {
        showDetails(product) {
            const ulid= product.ulid
            this.$router.push({
                name:'product',
                params: { ulid }
            })
        },
        getSearchResultFromHeader() {
            const searchResult= this.$route.params.searchResult
            console.log({searchResult})
            this.shopSearch= this.$route.params.searchResult
            // console.log('this.search',this.Xsearch);
        },
        selectAllCategories() {
            this.checkedCategories=true
            this.filteredProduct= this.products
        }
    },
    beforeMount () {
        this.loadingMessage= 'En cours de chargement ...'
    },
    async mounted () {
        await axios.get('products')
        .then(async resProduct=> {
            this.products = resProduct.data.result;
            this.filteredProduct= this.products
            console.log('products in mounted',this.products)

        })
        .catch(errProduct=> console.log(errProduct))

        this.allCategories=true

        this.getSearchResultFromHeader()
            this.loadingMessage= 'Il n\' y a pas de produit concernant à la recherche'
    },
    beforeRouteUpdate(to) {
        this.shopSearch= to.params.searchResult
    }
}
</script>

<style scoped>
@media screen and (max-width: 992px) {
  .sidebarCategories {
      display: none !important
  }
}
@media screen and (min-width: 992px) {
  .sidebarCategories2 {
      display: none !important
  }
}

.bg-gradient-success{
  /* background: linear-gradient(to right, #353535, #000); */
  background:linear-gradient(to right, #dfc23a, #ffb159)
}

.filter-category {
    max-height: 200px;
    overflow: auto;
}
</style>